<template>
  <div class="pb-3">
    <Loader v-if="performingRequest || (!activeWorkers || activeWorkers.length == 0)" />
    <div class="flex align-center flex-wrap mb-3">
        <button class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': isActive, 'btn__outlined': !isActive }" @click="activate()">Active & Onboarding</button>
        <button class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': isTerminated, 'btn__outlined': !isTerminated }" @click="terminate()">Terminated</button>
        <button class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': isOther, 'btn__outlined': !isOther }" @click="otherate()">Other</button>
    </div>
    <div class="dashboard__container--body" v-if="isActive">
      <vue-good-table
        @on-selected-rows-change="selectionChanged"
              :columns="columns"
              :rows="liveWorkers"
               styleClass="vgt-table striped"
               :select-options="{
                enabled: true,
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                selectOnCheckboxOnly: true,
              }"
              :search-options="{
                enabled: true,
                searchFn: mySearchFn,
                placeholder: 'Search this table',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 50,
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: {field: 'fullName', type: 'asc'}
              }">
            >
            
            <div slot="selected-row-actions">

              <button class="btn btn__small btn__dark mr-4 mt-1" @click="exportSelected()">
                Export <i class="fa-regular fa-arrow-up-right-from-square ml-2"></i>
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest2">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
              
              <transition name="fade">
                <div class="pa-3" v-if="showTextBox">
                  <textarea id="message" class="mt-3" cols="30" rows="4" style="min-width: 300px;" v-model="textMessage"></textarea>
                  
                  <!-- <input type="text" placeholder="Type Message" v-model="textMessage" style="min-width: 300px;" /> -->
                  <button v-if="textMessage" class="btn btn__primary btn__small mt-3 mb-3" @click="sendTextMessage()">Send
                    <transition name="fade">
                      <span class="ml-2" v-if="performingRequest3">
                      <i class="fa fa-spinner fa-spin"></i>
                      </span>
                    </transition>
                  </button>
                </div>
              </transition>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'photoUrl'">
                <span v-if="props.row.userDetails && props.row.userDetails.photoUrl">
                  <img :src="props.row.userDetails.photoUrl" alt="" style="width: 8rem; height:8rem; border-radius: 50%; padding: 0.25rem;">
                </span>
                <span v-else>
                   <img src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/avatar%20copy.png?alt=media&token=966c07c4-125a-490f-81be-4e2d26bf33fa" alt="" style="width: 8rem; height:8rem; border-radius: 50%; padding: 0.25rem;">
                </span>
              </span>
              <span v-else-if="props.column.field == 'fullName'">
                <span class="flex">
                  <router-link :to="'/users/' + props.row.userId" class="darkLink">
                    <h5 v-if="props.row.userDetails">
                    {{props.row.userDetails.firstName}} {{props.row.userDetails.lastName}}
                    </h5>
                  </router-link>
                </span>

                

                <div class="caption mt-2 mb-2 iconRow">

                 

                  <span v-if="props.row.userDetails && props.row.userDetails.beeline"><i class="fa-duotone fa-bee" style="--fa-primary-color: #6b6b6b; --fa-secondary-color: #fff700; --fa-secondary-opacity: 0.8;"></i></span>
                  <span v-if="props.row.userDetails && props.row.userDetails.hot"><i class="fa-solid fa-fire-flame" style="color:#E25822;"></i></span>
                  <span v-if="props.row.userDetails && props.row.userDetails.lead"><i class="fa-solid fa-badge-check blueHue"></i></span>
                  

                  <span v-if="props.row.userDetails && props.row.userDetails.groups && props.row.userDetails.groups.length >= 1">
                    <v-popover @apply-hide="clearPopover()" @apply-show="showPopover(props.row.userDetails.groups)">
                    <i class="fa-solid fa-user-group blueHue"></i>
                    <template slot="popover" style="display: inline;">
                      Member Of:
                      <div v-for="(item, index) in activeTitles">
                        {{item}} 
                      </div>
                      </template>
                    </v-popover>
                  </span>

                  <span v-if="props.row.userDetails && props.row.userDetails.groupApplications && props.row.userDetails.groupApplications.length >= 1">
                    <v-popover @apply-hide="clearPopover()" @apply-show="showPopover(props.row.userDetails.groupApplications)">
                    <i class="fa-solid fa-user-group orangeHue ml-2"></i>
                    <template slot="popover" style="display: inline;">
                      Applied To:
                      <div v-for="(item, index) in activeTitles">
                        {{item}} 
                      </div>
                      </template>
                    </v-popover>
                  </span>

                  <span v-if="props.row.userDetails && props.row.userDetails.id_card_path">
                    <v-popover>
                      <i class="fa-solid fa-address-card" style="color:#333333;"></i>
                      <template slot="popover" style="display: inline;">
                        <img :src="props.row.userDetails.id_card_path" alt="" style="width:100%;">
                      </template>
                    </v-popover>
                  </span>

                  <span v-if="props.row.userDetails && props.row.userDetails.picture">
                    <a :href="props.row.userDetails.picture" target="_blank">
                      <i class="fa-regular fa-file" style="color:#333333;"></i>
                    </a>
                  </span>

                  <span v-if="props.row.userDetails && props.row.userDetails.pipelineOrder">
                    <v-popover>
                      <i class="fa-solid fa-filter" style="color:#1F966F;"></i>
                      <template slot="popover" style="display: inline;">
                        <span v-if="props.row.userDetails.pipelineOrder.title">
                          {{props.row.userDetails.pipelineOrder.title}}
                        </span>
                      </template>
                    </v-popover>
                  </span>
                  <span v-if="props.row.userDetails && props.row.userDetails.touchLog && props.row.userDetails.touchLog[0]">
                    <v-popover>
                      <i class="fa-light fa-hand-point-up blueHueDark"></i>
                      <template slot="popover">
                        <div>{{findLast(props.row.userDetails.touchLog).contactedByFirstName}} {{findLast(props.row.userDetails.touchLog).contactedByLastName}}, {{findLast(props.row.userDetails.touchLog).method}}:</div>
                        <div>{{findLast(props.row.userDetails.touchLog).notes}}</div>
                      </template>
                    </v-popover>
                  </span>

                </div>
                <div class="mt-1">
                  <star-rating :read-only="true" :star-size="12" v-if="props.row && props.row.userDetails && props.row.userDetails.rating" v-model="props.row.userDetails.rating" class="caption"></star-rating>
                </div>







                
                <div class="flex justify-flex-start mt-0">
                  <span v-if="props.row && props.row.userDetails && props.row.userDetails.points" class="caption flex align-center">
                    {{props.row.userDetails.points}} Points
                  </span>
                </div>
                <div class="flex justify-flex-start mt-0 caption" v-if="props.row && props.row.userDetails && props.row.userDetails.dob">
                  DOB: {{props.row.userDetails.dob | moment("MM/DD/YYYY") }}
                </div>
                <div class="mt-0 caption" v-if="props.row.userDetails && props.row.userDetails.hireDate" style="color: #21bcf3;">HD: {{props.row.userDetails.hireDate | moment("MM/DD/YYYY")}}</div>
                <div class="mt-0 caption" v-if="props.row.userDetails && props.row.userDetails.terminationDate" style="color:#ef4b46;">TD: {{props.row.userDetails.terminationDate | moment("MM/DD/YYYY")}}</div>
                <div class="mt-1 mb-1 caption" v-if="props.row && props.row">
                  <InterviewStatus :user="props.row.userDetails" />
                </div>
                <div class="caption mt-1" v-if="props.row.userDetails && props.row.userDetails.positionPreference && props.row.userDetails.positionPreference.length >= 1">
                  Position Preferences: <button class="flatChip mr-1" v-for="(item, index) in props.row.userDetails.positionPreference">{{item.title}}</button>
                </div>
                <div class="caption mt-1" v-if="props.row.userDetails && props.row.userDetails.positionPreference && !props.row.userDetails.positionPreference.length">
                Position Preferences: <button class="flatChip mr-1">{{props.row.userDetails.positionPreference.title}}</button>
              </div>

              <div class="caption mt-1" v-if="(props.row && props.row.userDetails && props.row.userDetails.logins && props.row.userDetails.logins.length > 0)">Last Login: {{ lastLoginTime(props.row) }}</div>
   

                <!-- <button v-if="(action && action == 'groupUser') && !props.row.pipelineOrder" class="btn btn__xsmall btn__outlined mt-1 pb-2" @click="addPipeline(props.row)">Add to Pipeline <i class="fa-light fa-filter ml-2"></i></button> -->

              </span>
              <span v-else-if="props.column.field == 'created'" class="caption">
                {{formatDate(props.row.created)}}
              </span>
              <span v-else-if="(props.column.field == 'estimatedStartDate') && (isPipeline == true || (showShiftPreference == true))">
           
                <div style="padding: 0.75em 0.75em 0.75em 0.75em;" v-if="showShiftPreference == true">

                  <input type="date" v-model.trim="props.row.estimatedStartDate" id="estimatedStartDate" @change="updatePipe(props.row)" :readonly="cancelledPipe" />
                </div>
              </span>
              
              
              <span v-else-if="props.column.field == 'onboardFlow'">
                <!-- <div class="caption">
                  <ContractorStatus :user="props.row.userDetails" />
                 
                </div> -->
                <div class="caption" v-if="props.row && props.row.userDetails">
                  <EmployeeStatus :user="props.row.userDetails" />
                 
                </div>
              </span>
              
              <span v-else-if="props.column.field == 'employmentStatus'">

                <div style="width: 100px;" v-if="!showShiftPreference">

                  <span class="caption" v-if="props.row.employmentStatus">{{props.row.employmentStatus}}</span>
                </div>
              </span>
              <span v-else-if="props.column.field == 'employmentAssignment'">

               

                  <span class="caption" v-if="props.row.userDetails && props.row.userDetails.employmentAssignment">{{props.row.userDetails.employmentAssignment.eventName}}</span>
               
              </span>
              <span v-else-if="props.column.field == 'onboardStatus'">
                <div v-if="props.row.workerId && props.row.evereeOnboardingComplete" class="caption" style="color:#00C897;">
                  Contractor
                </div>
                <div v-if="props.row.workerId && !props.row.evereeOnboardingComplete" class="caption" style="color:#f0ad4e;">
                  Contractor 
                </div>
                <div v-if="!props.row.workerId" class="caption" style="color:#ef4b46;">
                  Contractor
                </div>
                <div v-if="!props.row.employeeId" class="caption" >
                  <div style="color:#ef4b46;">Employee</div>
                  
                </div>
                <div v-if="props.row.employeeId && !props.row.employeeOnboardingComplete" class="caption">
                  <div style="color:#f0ad4e;">Employee</div>
                  
                </div>
                <div v-if="props.row.employeeId && props.row.employeeOnboardingComplete" class="caption">
                  <div style="color:#00C897;">Employee</div>

                </div>
              </span>
              <span v-else-if="props.column.field == 'phone'">
                <span v-if="props.row.userDetails && props.row.userDetails.phone" class="caption">
                  <a :href="'sms:' + props.row.userDetails.phone" class="darkLink">{{props.row.userDetails.phone}}</a>
                </span>
              </span>
              <span v-else-if="props.column.field == 'city'">
                <span class="caption" v-if="props.row.userDetails && props.row.userDetails.address && props.row.userDetails.address.city">{{props.row.userDetails.address.city}}</span>

                

              </span>
              <span v-else-if="props.column.field == 'state'">
                <span class="caption" v-if="props.row.userDetails && props.row.userDetails.address && props.row.userDetails.address.state">{{props.row.userDetails.address.state}}</span>
              </span>
              <span v-else-if="props.column.field == 'link'">
                <router-link :to="`/users/` + props.row.id" target="_blank">
                  <i class="fas fa-external-link ml-3 mr-3"></i>
                </router-link>
              </span>
              <span v-else-if="props.column.field == 'skills'">
                <span v-if="!props.row.skills || props.row.skills.length == 0">
                  <i class="fad fa-briefcase ml-2 mr-2" style="opacity:50%;"></i>
                </span>
                <span v-if="props.row.skills && props.row.skills.length > 0">
                  <v-popover>
                    <i class="fad fa-briefcase ml-2 mr-2 success"></i>
                    <template slot="popover">
                      <span v-for="z in props.row.skills">{{z.title}} / </span>
                    </template>
                  </v-popover>
                </span>
              </span>
              <span v-else-if="props.column.field == 'blacklist'">
                <span v-if="props.row.blacklist && props.row.blacklist.length >=1" style="display:inline;">
                  <v-popover>
                    <i class="fas fa-exclamation-triangle ml-2 mr-2" style="color:red;"></i>
                    <template slot="popover">
                    <span v-for="z in props.row.blacklist">{{z.title}}</span>
                  </template>
                  </v-popover>
                </span>
              </span>

              

              <span v-else-if="props.column.field == 'status'">
                <div style="width:calc(200px + 1.5em); padding: 0.75em 0.75em 0.75em 0.75em;" v-if="props.row && props.row.userDetails">
                  <div>
                    <v-select
                      class="mt-2"
                      label="title" 
                      :options="statuses"
                      v-model="props.row.userDetails.employmentStatus"        
                      :id="props.row.id + `employmentStatus`"
                      @input="updateStatus(props.row)"
                      >
                    </v-select>
                  </div>
                </div>
              </span>
              
              

              

           
              <span v-else-if="props.column.field == 'delete'">
               <button v-tooltip="'drop worker'" class="btn btn__outlined btn__small ml-3" @click="drop(props.row)">
                    Drop
                    <i class="fa-solid fa-xmark ml-2"></i>
                  </button>
                </span>
              </span>
              

              <span v-else-if="props.column.field == 'assignment'">
                <span>
                  {{props.row.eventTitle}}
                </span>
              </span>
              <span v-else-if="props.column.field == 'shiftJob'">
                <span>
                  {{props.row.shiftJob}}
                </span>
              </span>
              


               <span v-else>
                <!-- <span class="caption">
                  {{props.formattedRow[props.column.field]}}
                </span> -->
              </span>
            </template>
      </vue-good-table>
    </div>
    <div class="dashboard__container--body" v-if="isTerminated">
      <vue-good-table
        @on-selected-rows-change="selectionChanged"
              :columns="columns"
              :rows="deadWorkers"
               styleClass="vgt-table striped"
               :select-options="{
                enabled: true,
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                selectOnCheckboxOnly: true,
              }"
              :search-options="{
                enabled: true,
                searchFn: mySearchFn,
                placeholder: 'Search this table',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 50,
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: {field: 'fullName', type: 'asc'}
              }"
            >
            
            <div slot="selected-row-actions">

              <button class="btn btn__small btn__dark mr-4 mt-1" @click="exportSelected()">
                Export <i class="fa-regular fa-arrow-up-right-from-square ml-2"></i>
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest2">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
              
              <transition name="fade">
                <div class="pa-3" v-if="showTextBox">
                  <textarea id="message" class="mt-3" cols="30" rows="4" style="min-width: 300px;" v-model="textMessage"></textarea>
                  
                  <!-- <input type="text" placeholder="Type Message" v-model="textMessage" style="min-width: 300px;" /> -->
                  <button v-if="textMessage" class="btn btn__primary btn__small mt-3 mb-3" @click="sendTextMessage()">Send
                    <transition name="fade">
                      <span class="ml-2" v-if="performingRequest3">
                      <i class="fa fa-spinner fa-spin"></i>
                      </span>
                    </transition>
                  </button>
                </div>
              </transition>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'photoUrl'">
                <span v-if="props.row.userDetails && props.row.userDetails.photoUrl">
                  <img :src="props.row.userDetails.photoUrl" alt="" style="width: 8rem; height:8rem; border-radius: 50%; padding: 0.25rem;">
                </span>
                <span v-else>
                   <img src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/avatar%20copy.png?alt=media&token=966c07c4-125a-490f-81be-4e2d26bf33fa" alt="" style="width: 8rem; height:8rem; border-radius: 50%; padding: 0.25rem;">
                </span>
              </span>
              <span v-else-if="props.column.field == 'fullName'">
                <span class="flex">
                  <router-link :to="'/users/' + props.row.userId" class="darkLink">
                    <h5 v-if="props.row.userDetails">
                    {{props.row.userDetails.firstName}} {{props.row.userDetails.lastName}}
                    </h5>
                  </router-link>
                </span>

                

                <div class="caption mt-2 mb-2 iconRow">

                 

                  <span v-if="props.row.userDetails && props.row.userDetails.beeline"><i class="fa-duotone fa-bee" style="--fa-primary-color: #6b6b6b; --fa-secondary-color: #fff700; --fa-secondary-opacity: 0.8;"></i></span>
                  <span v-if="props.row.userDetails && props.row.userDetails.hot"><i class="fa-solid fa-fire-flame" style="color:#E25822;"></i></span>
                  <span v-if="props.row.userDetails && props.row.userDetails.lead"><i class="fa-solid fa-badge-check blueHue"></i></span>
                  

                  <span v-if="props.row.userDetails && props.row.userDetails.groups && props.row.userDetails.groups.length >= 1">
                    <v-popover @apply-hide="clearPopover()" @apply-show="showPopover(props.row.userDetails.groups)">
                    <i class="fa-solid fa-user-group blueHue"></i>
                    <template slot="popover" style="display: inline;">
                      Member Of:
                      <div v-for="(item, index) in activeTitles">
                        {{item}} 
                      </div>
                      </template>
                    </v-popover>
                  </span>

                  <span v-if="props.row.userDetails && props.row.userDetails.groupApplications && props.row.userDetails.groupApplications.length >= 1">
                    <v-popover @apply-hide="clearPopover()" @apply-show="showPopover(props.row.userDetails.groupApplications)">
                    <i class="fa-solid fa-user-group orangeHue ml-2"></i>
                    <template slot="popover" style="display: inline;">
                      Applied To:
                      <div v-for="(item, index) in activeTitles">
                        {{item}} 
                      </div>
                      </template>
                    </v-popover>
                  </span>

                  <span v-if="props.row.userDetails && props.row.userDetails.id_card_path">
                    <v-popover>
                      <i class="fa-solid fa-address-card" style="color:#333333;"></i>
                      <template slot="popover" style="display: inline;">
                        <img :src="props.row.userDetails.id_card_path" alt="" style="width:100%;">
                      </template>
                    </v-popover>
                  </span>

                  <span v-if="props.row.userDetails && props.row.userDetails.picture">
                    <a :href="props.row.userDetails.picture" target="_blank">
                      <i class="fa-regular fa-file" style="color:#333333;"></i>
                    </a>
                  </span>

                  <span v-if="props.row.userDetails && props.row.userDetails.pipelineOrder">
                    <v-popover>
                      <i class="fa-solid fa-filter" style="color:#1F966F;"></i>
                      <template slot="popover" style="display: inline;">
                        <span v-if="props.row.userDetails.pipelineOrder.title">
                          {{props.row.userDetails.pipelineOrder.title}}
                        </span>
                      </template>
                    </v-popover>
                  </span>
                  <span v-if="props.row.userDetails && props.row.userDetails.touchLog && props.row.userDetails.touchLog[0]">
                    <v-popover>
                      <i class="fa-light fa-hand-point-up blueHueDark"></i>
                      <template slot="popover">
                        <div>{{findLast(props.row.userDetails.touchLog).contactedByFirstName}} {{findLast(props.row.userDetails.touchLog).contactedByLastName}}, {{findLast(props.row.userDetails.touchLog).method}}:</div>
                        <div>{{findLast(props.row.userDetails.touchLog).notes}}</div>
                      </template>
                    </v-popover>
                  </span>

                </div>
                <div class="mt-1">
                  <star-rating :read-only="true" :star-size="12" v-if="props.row && props.row.userDetails && props.row.userDetails.rating" v-model="props.row.userDetails.rating" class="caption"></star-rating>
                </div>







                
                <div class="flex justify-flex-start mt-0">
                  <span v-if="props.row && props.row.userDetails && props.row.userDetails.points" class="caption flex align-center">
                    {{props.row.userDetails.points}} Points
                  </span>
                </div>
                <div class="flex justify-flex-start mt-0 caption" v-if="props.row && props.row.userDetails && props.row.userDetails.dob">
                  DOB: {{props.row.userDetails.dob | moment("MM/DD/YYYY") }}
                </div>
                <div class="mt-0 caption" v-if="props.row.userDetails && props.row.userDetails.hireDate" style="color: #21bcf3;">HD: {{props.row.userDetails.hireDate | moment("MM/DD/YYYY")}}</div>
                <div class="mt-0 caption" v-if="props.row.userDetails && props.row.userDetails.terminationDate" style="color:#ef4b46;">TD: {{props.row.userDetails.terminationDate | moment("MM/DD/YYYY")}}</div>
                <div class="mt-1 mb-1 caption" v-if="props.row && props.row">
                  <InterviewStatus :user="props.row.userDetails" />
                </div>
                <div class="caption mt-1" v-if="props.row.userDetails && props.row.userDetails.positionPreference && props.row.userDetails.positionPreference.length >= 1">
                  Position Preferences: <button class="flatChip mr-1" v-for="(item, index) in props.row.userDetails.positionPreference">{{item.title}}</button>
                </div>
                <div class="caption mt-1" v-if="props.row.userDetails && props.row.userDetails.positionPreference && !props.row.userDetails.positionPreference.length">
                Position Preferences: <button class="flatChip mr-1">{{props.row.userDetails.positionPreference.title}}</button>
              </div>

              <div class="caption mt-1" v-if="(props.row && props.row.userDetails && props.row.userDetails.logins && props.row.userDetails.logins.length > 0)">Last Login: {{ lastLoginTime(props.row) }}</div>
   

                <!-- <button v-if="(action && action == 'groupUser') && !props.row.pipelineOrder" class="btn btn__xsmall btn__outlined mt-1 pb-2" @click="addPipeline(props.row)">Add to Pipeline <i class="fa-light fa-filter ml-2"></i></button> -->

              </span>
              <span v-else-if="props.column.field == 'created'" class="caption">
                {{formatDate(props.row.created)}}
              </span>
              <span v-else-if="(props.column.field == 'estimatedStartDate') && (isPipeline == true || (showShiftPreference == true))">
           
                <div style="padding: 0.75em 0.75em 0.75em 0.75em;" v-if="showShiftPreference == true">

                  <input type="date" v-model.trim="props.row.estimatedStartDate" id="estimatedStartDate" @change="updatePipe(props.row)" :readonly="cancelledPipe" />
                </div>
              </span>
              
              
              <span v-else-if="props.column.field == 'onboardFlow'">
                <!-- <div class="caption">
                  <ContractorStatus :user="props.row.userDetails" />
                 
                </div> -->
                <div class="caption" v-if="props.row && props.row.userDetails">
                  <EmployeeStatus :user="props.row.userDetails" />
                 
                </div>
              </span>
              
              <span v-else-if="props.column.field == 'employmentStatus'">

                <div style="width: 100px;" v-if="!showShiftPreference">

                  <span class="caption" v-if="props.row.employmentStatus">{{props.row.employmentStatus}}</span>
                </div>
              </span>
              <span v-else-if="props.column.field == 'employmentAssignment'">

               

                  <span class="caption" v-if="props.row.userDetails && props.row.userDetails.employmentAssignment">{{props.row.userDetails.employmentAssignment.eventName}}</span>
               
              </span>
              <span v-else-if="props.column.field == 'onboardStatus'">
                <div v-if="props.row.workerId && props.row.evereeOnboardingComplete" class="caption" style="color:#00C897;">
                  Contractor
                </div>
                <div v-if="props.row.workerId && !props.row.evereeOnboardingComplete" class="caption" style="color:#f0ad4e;">
                  Contractor 
                </div>
                <div v-if="!props.row.workerId" class="caption" style="color:#ef4b46;">
                  Contractor
                </div>
                <div v-if="!props.row.employeeId" class="caption" >
                  <div style="color:#ef4b46;">Employee</div>
                  
                </div>
                <div v-if="props.row.employeeId && !props.row.employeeOnboardingComplete" class="caption">
                  <div style="color:#f0ad4e;">Employee</div>
                  
                </div>
                <div v-if="props.row.employeeId && props.row.employeeOnboardingComplete" class="caption">
                  <div style="color:#00C897;">Employee</div>

                </div>
              </span>
              <span v-else-if="props.column.field == 'phone'">
                <span v-if="props.row.userDetails && props.row.userDetails.phone" class="caption">
                  <a :href="'sms:' + props.row.userDetails.phone" class="darkLink">{{props.row.userDetails.phone}}</a>
                </span>
              </span>
              <span v-else-if="props.column.field == 'city'">
                <span class="caption" v-if="props.row.userDetails && props.row.userDetails.address && props.row.userDetails.address.city">{{props.row.userDetails.address.city}}</span>

                

              </span>
              <span v-else-if="props.column.field == 'state'">
                <span class="caption" v-if="props.row.userDetails && props.row.userDetails.address && props.row.userDetails.address.state">{{props.row.userDetails.address.state}}</span>
              </span>
              <span v-else-if="props.column.field == 'link'">
                <router-link :to="`/users/` + props.row.id" target="_blank">
                  <i class="fas fa-external-link ml-3 mr-3"></i>
                </router-link>
              </span>
              <span v-else-if="props.column.field == 'skills'">
                <span v-if="!props.row.skills || props.row.skills.length == 0">
                  <i class="fad fa-briefcase ml-2 mr-2" style="opacity:50%;"></i>
                </span>
                <span v-if="props.row.skills && props.row.skills.length > 0">
                  <v-popover>
                    <i class="fad fa-briefcase ml-2 mr-2 success"></i>
                    <template slot="popover">
                      <span v-for="z in props.row.skills">{{z.title}} / </span>
                    </template>
                  </v-popover>
                </span>
              </span>
              <span v-else-if="props.column.field == 'blacklist'">
                <span v-if="props.row.blacklist && props.row.blacklist.length >=1" style="display:inline;">
                  <v-popover>
                    <i class="fas fa-exclamation-triangle ml-2 mr-2" style="color:red;"></i>
                    <template slot="popover">
                    <span v-for="z in props.row.blacklist">{{z.title}}</span>
                  </template>
                  </v-popover>
                </span>
              </span>

              

              <span v-else-if="props.column.field == 'status'">
                <div style="width:calc(200px + 1.5em); padding: 0.75em 0.75em 0.75em 0.75em;" v-if="props.row && props.row.userDetails">
                  <div>
                    <v-select
                      class="mt-2"
                      label="title" 
                      :options="statuses"
                      v-model="props.row.userDetails.employmentStatus"        
                      :id="props.row.id + `employmentStatus`"
                      @input="updateStatus(props.row)"
                      >
                    </v-select>
                  </div>
                </div>
              </span>
              
              

              

           
              <span v-else-if="props.column.field == 'delete'">
               <button v-tooltip="'drop worker'" class="btn btn__outlined btn__small ml-3" @click="drop(props.row)">
                    Drop
                    <i class="fa-solid fa-xmark ml-2"></i>
                  </button>
                </span>
              </span>
              

              <span v-else-if="props.column.field == 'assignment'">
                <span>
                  {{props.row.eventTitle}}
                </span>
              </span>
              <span v-else-if="props.column.field == 'shiftJob'">
                <span>
                  {{props.row.shiftJob}}
                </span>
              </span>
              


               <span v-else>
                <!-- <span class="caption">
                  {{props.formattedRow[props.column.field]}}
                </span> -->
              </span>
            </template>
      </vue-good-table>
    </div>
    <div class="dashboard__container--body" v-if="isOther">
      <vue-good-table
        @on-selected-rows-change="selectionChanged"
              :columns="columns"
              :rows="otherWorkers"
               styleClass="vgt-table striped"
               :select-options="{
                enabled: true,
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                selectOnCheckboxOnly: true,
              }"
              :search-options="{
                enabled: true,
                searchFn: mySearchFn,
                placeholder: 'Search this table',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 50,
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: {field: 'fullName', type: 'asc'}
              }"
            >
            
            <div slot="selected-row-actions">

              <button class="btn btn__small btn__dark mr-4 mt-1" @click="exportSelected()">
                Export <i class="fa-regular fa-arrow-up-right-from-square ml-2"></i>
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest2">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
              
              <transition name="fade">
                <div class="pa-3" v-if="showTextBox">
                  <textarea id="message" class="mt-3" cols="30" rows="4" style="min-width: 300px;" v-model="textMessage"></textarea>
                  
                  <!-- <input type="text" placeholder="Type Message" v-model="textMessage" style="min-width: 300px;" /> -->
                  <button v-if="textMessage" class="btn btn__primary btn__small mt-3 mb-3" @click="sendTextMessage()">Send
                    <transition name="fade">
                      <span class="ml-2" v-if="performingRequest3">
                      <i class="fa fa-spinner fa-spin"></i>
                      </span>
                    </transition>
                  </button>
                </div>
              </transition>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'photoUrl'">
                <span v-if="props.row.userDetails && props.row.userDetails.photoUrl">
                  <img :src="props.row.userDetails.photoUrl" alt="" style="width: 8rem; height:8rem; border-radius: 50%; padding: 0.25rem;">
                </span>
                <span v-else>
                   <img src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/avatar%20copy.png?alt=media&token=966c07c4-125a-490f-81be-4e2d26bf33fa" alt="" style="width: 8rem; height:8rem; border-radius: 50%; padding: 0.25rem;">
                </span>
              </span>
              <span v-else-if="props.column.field == 'fullName'">
                <span class="flex">
                  <router-link :to="'/users/' + props.row.userId" class="darkLink">
                    <h5 v-if="props.row.userDetails">
                    {{props.row.userDetails.firstName}} {{props.row.userDetails.lastName}}
                    </h5>
                  </router-link>
                </span>

                

                <div class="caption mt-2 mb-2 iconRow">

                 

                  <span v-if="props.row.userDetails && props.row.userDetails.beeline"><i class="fa-duotone fa-bee" style="--fa-primary-color: #6b6b6b; --fa-secondary-color: #fff700; --fa-secondary-opacity: 0.8;"></i></span>
                  <span v-if="props.row.userDetails && props.row.userDetails.hot"><i class="fa-solid fa-fire-flame" style="color:#E25822;"></i></span>
                  <span v-if="props.row.userDetails && props.row.userDetails.lead"><i class="fa-solid fa-badge-check blueHue"></i></span>
                  

                  <span v-if="props.row.userDetails && props.row.userDetails.groups && props.row.userDetails.groups.length >= 1">
                    <v-popover @apply-hide="clearPopover()" @apply-show="showPopover(props.row.userDetails.groups)">
                    <i class="fa-solid fa-user-group blueHue"></i>
                    <template slot="popover" style="display: inline;">
                      Member Of:
                      <div v-for="(item, index) in activeTitles">
                        {{item}} 
                      </div>
                      </template>
                    </v-popover>
                  </span>

                  <span v-if="props.row.userDetails && props.row.userDetails.groupApplications && props.row.userDetails.groupApplications.length >= 1">
                    <v-popover @apply-hide="clearPopover()" @apply-show="showPopover(props.row.userDetails.groupApplications)">
                    <i class="fa-solid fa-user-group orangeHue ml-2"></i>
                    <template slot="popover" style="display: inline;">
                      Applied To:
                      <div v-for="(item, index) in activeTitles">
                        {{item}} 
                      </div>
                      </template>
                    </v-popover>
                  </span>

                  <span v-if="props.row.userDetails && props.row.userDetails.id_card_path">
                    <v-popover>
                      <i class="fa-solid fa-address-card" style="color:#333333;"></i>
                      <template slot="popover" style="display: inline;">
                        <img :src="props.row.userDetails.id_card_path" alt="" style="width:100%;">
                      </template>
                    </v-popover>
                  </span>

                  <span v-if="props.row.userDetails && props.row.userDetails.picture">
                    <a :href="props.row.userDetails.picture" target="_blank">
                      <i class="fa-regular fa-file" style="color:#333333;"></i>
                    </a>
                  </span>

                  <span v-if="props.row.userDetails && props.row.userDetails.pipelineOrder">
                    <v-popover>
                      <i class="fa-solid fa-filter" style="color:#1F966F;"></i>
                      <template slot="popover" style="display: inline;">
                        <span v-if="props.row.userDetails.pipelineOrder.title">
                          {{props.row.userDetails.pipelineOrder.title}}
                        </span>
                      </template>
                    </v-popover>
                  </span>
                  <span v-if="props.row.userDetails && props.row.userDetails.touchLog && props.row.userDetails.touchLog[0]">
                    <v-popover>
                      <i class="fa-light fa-hand-point-up blueHueDark"></i>
                      <template slot="popover">
                        <div>{{findLast(props.row.userDetails.touchLog).contactedByFirstName}} {{findLast(props.row.userDetails.touchLog).contactedByLastName}}, {{findLast(props.row.userDetails.touchLog).method}}:</div>
                        <div>{{findLast(props.row.userDetails.touchLog).notes}}</div>
                      </template>
                    </v-popover>
                  </span>

                </div>
                <div class="mt-1">
                  <star-rating :read-only="true" :star-size="12" v-if="props.row && props.row.userDetails && props.row.userDetails.rating" v-model="props.row.userDetails.rating" class="caption"></star-rating>
                </div>







                
                <div class="flex justify-flex-start mt-0">
                  <span v-if="props.row && props.row.userDetails && props.row.userDetails.points" class="caption flex align-center">
                    {{props.row.userDetails.points}} Points
                  </span>
                </div>
                <div class="flex justify-flex-start mt-0 caption" v-if="props.row && props.row.userDetails && props.row.userDetails.dob">
                  DOB: {{props.row.userDetails.dob | moment("MM/DD/YYYY") }}
                </div>
                <div class="mt-0 caption" v-if="props.row.userDetails && props.row.userDetails.hireDate" style="color: #21bcf3;">HD: {{props.row.userDetails.hireDate | moment("MM/DD/YYYY")}}</div>
                <div class="mt-0 caption" v-if="props.row.userDetails && props.row.userDetails.terminationDate" style="color:#ef4b46;">TD: {{props.row.userDetails.terminationDate | moment("MM/DD/YYYY")}}</div>
                <div class="mt-1 mb-1 caption" v-if="props.row && props.row">
                  <InterviewStatus :user="props.row.userDetails" />
                </div>
                <div class="caption mt-1" v-if="props.row.userDetails && props.row.userDetails.positionPreference && props.row.userDetails.positionPreference.length >= 1">
                  Position Preferences: <button class="flatChip mr-1" v-for="(item, index) in props.row.userDetails.positionPreference">{{item.title}}</button>
                </div>
                <div class="caption mt-1" v-if="props.row.userDetails && props.row.userDetails.positionPreference && !props.row.userDetails.positionPreference.length">
                Position Preferences: <button class="flatChip mr-1">{{props.row.userDetails.positionPreference.title}}</button>
              </div>

              <div class="caption mt-1" v-if="(props.row && props.row.userDetails && props.row.userDetails.logins && props.row.userDetails.logins.length > 0)">Last Login: {{ lastLoginTime(props.row) }}</div>
   

                <!-- <button v-if="(action && action == 'groupUser') && !props.row.pipelineOrder" class="btn btn__xsmall btn__outlined mt-1 pb-2" @click="addPipeline(props.row)">Add to Pipeline <i class="fa-light fa-filter ml-2"></i></button> -->

              </span>
              <span v-else-if="props.column.field == 'created'" class="caption">
                {{formatDate(props.row.created)}}
              </span>
              <span v-else-if="(props.column.field == 'estimatedStartDate') && (isPipeline == true || (showShiftPreference == true))">
           
                <div style="padding: 0.75em 0.75em 0.75em 0.75em;" v-if="showShiftPreference == true">

                  <input type="date" v-model.trim="props.row.estimatedStartDate" id="estimatedStartDate" @change="updatePipe(props.row)" :readonly="cancelledPipe" />
                </div>
              </span>
              
              
              <span v-else-if="props.column.field == 'onboardFlow'">
                <!-- <div class="caption">
                  <ContractorStatus :user="props.row.userDetails" />
                 
                </div> -->
                <div class="caption" v-if="props.row && props.row.userDetails">
                  <EmployeeStatus :user="props.row.userDetails" />
                 
                </div>
              </span>
              
              <span v-else-if="props.column.field == 'employmentStatus'">

                <div style="width: 100px;" v-if="!showShiftPreference">

                  <span class="caption" v-if="props.row.employmentStatus">{{props.row.employmentStatus}}</span>
                </div>
              </span>
              <span v-else-if="props.column.field == 'employmentAssignment'">

               

                  <span class="caption" v-if="props.row.userDetails && props.row.userDetails.employmentAssignment">{{props.row.userDetails.employmentAssignment.eventName}}</span>
               
              </span>
              <span v-else-if="props.column.field == 'onboardStatus'">
                <div v-if="props.row.workerId && props.row.evereeOnboardingComplete" class="caption" style="color:#00C897;">
                  Contractor
                </div>
                <div v-if="props.row.workerId && !props.row.evereeOnboardingComplete" class="caption" style="color:#f0ad4e;">
                  Contractor 
                </div>
                <div v-if="!props.row.workerId" class="caption" style="color:#ef4b46;">
                  Contractor
                </div>
                <div v-if="!props.row.employeeId" class="caption" >
                  <div style="color:#ef4b46;">Employee</div>
                  
                </div>
                <div v-if="props.row.employeeId && !props.row.employeeOnboardingComplete" class="caption">
                  <div style="color:#f0ad4e;">Employee</div>
                  
                </div>
                <div v-if="props.row.employeeId && props.row.employeeOnboardingComplete" class="caption">
                  <div style="color:#00C897;">Employee</div>

                </div>
              </span>
              <span v-else-if="props.column.field == 'phone'">
                <span v-if="props.row.userDetails && props.row.userDetails.phone" class="caption">
                  <a :href="'sms:' + props.row.userDetails.phone" class="darkLink">{{props.row.userDetails.phone}}</a>
                </span>
              </span>
              <span v-else-if="props.column.field == 'city'">
                <span class="caption" v-if="props.row.userDetails && props.row.userDetails.address && props.row.userDetails.address.city">{{props.row.userDetails.address.city}}</span>

                

              </span>
              <span v-else-if="props.column.field == 'state'">
                <span class="caption" v-if="props.row.userDetails && props.row.userDetails.address && props.row.userDetails.address.state">{{props.row.userDetails.address.state}}</span>
              </span>
              <span v-else-if="props.column.field == 'link'">
                <router-link :to="`/users/` + props.row.id" target="_blank">
                  <i class="fas fa-external-link ml-3 mr-3"></i>
                </router-link>
              </span>
              <span v-else-if="props.column.field == 'skills'">
                <span v-if="!props.row.skills || props.row.skills.length == 0">
                  <i class="fad fa-briefcase ml-2 mr-2" style="opacity:50%;"></i>
                </span>
                <span v-if="props.row.skills && props.row.skills.length > 0">
                  <v-popover>
                    <i class="fad fa-briefcase ml-2 mr-2 success"></i>
                    <template slot="popover">
                      <span v-for="z in props.row.skills">{{z.title}} / </span>
                    </template>
                  </v-popover>
                </span>
              </span>
              <span v-else-if="props.column.field == 'blacklist'">
                <span v-if="props.row.blacklist && props.row.blacklist.length >=1" style="display:inline;">
                  <v-popover>
                    <i class="fas fa-exclamation-triangle ml-2 mr-2" style="color:red;"></i>
                    <template slot="popover">
                    <span v-for="z in props.row.blacklist">{{z.title}}</span>
                  </template>
                  </v-popover>
                </span>
              </span>

              

              <span v-else-if="props.column.field == 'status'">
                <div style="width:calc(200px + 1.5em); padding: 0.75em 0.75em 0.75em 0.75em;" v-if="props.row && props.row.userDetails">
                  <div>
                    <v-select
                      class="mt-2"
                      label="title" 
                      :options="statuses"
                      v-model="props.row.userDetails.employmentStatus"        
                      :id="props.row.id + `employmentStatus`"
                      @input="updateStatus(props.row)"
                      >
                    </v-select>
                  </div>
                </div>
              </span>
              
              

              

           
              <span v-else-if="props.column.field == 'delete'">
               <button v-tooltip="'drop worker'" class="btn btn__outlined btn__small ml-3" @click="drop(props.row)">
                    Drop
                    <i class="fa-solid fa-xmark ml-2"></i>
                  </button>
                </span>
              </span>
              

              <span v-else-if="props.column.field == 'assignment'">
                <span>
                  {{props.row.eventTitle}}
                </span>
              </span>
              <span v-else-if="props.column.field == 'shiftJob'">
                <span>
                  {{props.row.shiftJob}}
                </span>
              </span>
              


               <span v-else>
                <!-- <span class="caption">
                  {{props.formattedRow[props.column.field]}}
                </span> -->
              </span>
            </template>
      </vue-good-table>
    </div>
  </div>
</template>

<style scoped>

  .cancelled  {
    background: #ff000029 !important;
  }
/*   .cancelled .vgt-inner-wrap .vgt-responsive .striped  {
    background: red;
  }

  .cancelled table.vgt-table{
    background: red !important;
  } */

  table.vgt-table {
    background-color: transparent !important;
}

  .v-select {

    border: 1px solid #ddd;
}

   table .vs__dropdown-toggle {
    background: white !important;
    border-radius: 0 !important;
    border: 1px solid #ddd;
    padding: 0.5rem 0.25rem;
    min-height: 36px;
}
.iconRow i {
  font-size: 1.4rem;
  margin-right: 1rem;
}
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import * as moment from 'moment'
import router from '@/router'
import StarRating from 'vue-star-rating'
import EmployeeStatus from '@/components/EmployeeStatus.vue'
import InterviewStatus from '@/components/InterviewStatus.vue'
import ContractorStatus from '@/components/ContractorStatus.vue'
import ExportService from "@/services/ExportService"
const fb = require('../../firebaseConfig.js')

export default {
  name: 'payrollWorkers',
  data() {
    return {
      isActive: true,
      isTerminated: false,
      isOther: false,
      statuses: ['Onboarding', 'Active', 'Terminated', 'On-Hold', 'Resigned', 'On-Leave'],
      batch: [],
      activeTitles: [],
      performingRequest: false,
      performingRequest2: false,
      showShiftPreference: true,
      showTextBox: false,
      columns: [
        {
          field: 'photoUrl',
          sortable: false,
          width:'96px',
          tdClass: 'text-center',
        },
        // {
        //   label: 'Pipeline',
        //   field: 'pipelineAction',
        //   sortable: false,
        //   tdClass: 'pa-0'
        // },
        
        {
          label: 'Name',
          field: 'fullName',
          // sortable: false,
          width:'224px',
        },
        // {
        //   label: 'Last Touch',
        //   field: 'touchLog',
        //   sortable: false,
        //   width:'180px'
        // },
        // {
        //   label: '',
        //   field: 'watchlist',
        //   sortable: false,
        //   width:'40px',
        // },
        // {
        //   label: '',
        //   field: 'notes',
        //   sortable: false,
        //   tdClass: 'pa-0'
        // },
        // {
        //   label: 'Position Preference',
        //   field: 'position',
        //   sortable: false,
        //   tdClass: 'pa-0'
        // },
        // {
        //   label: 'Shift Preference',
        //   field: 'shift',
        //   sortable: false,
        //   tdClass: 'pa-0'
        // },
        // {
        //   label: 'Est. Start Date',
        //   field: 'estimatedStartDate',
        //   sortable: false,
        //   tdClass: 'pa-0'
        // },
        {
          label: 'Onboarding',
          field: 'onboardFlow',
          width:'160px',
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
        },
        {
          label: 'Assignment',
          field: 'assignment',
        },
        {
          label: 'Position',
          field: 'shiftJob',
        },
        // {
        //   label: 'Payroll',
        //   field: 'userDetails.onboardStatus',
        //   sortable: false,
        //   width:'80px',
        // },
        {
          label: 'City',
          field: 'city',
          width:'120px',
        },
        {
          label: 'State',
          field: 'state',
          width:'40px',
        },
        // {
        //   label: 'Joined',
        //   field: 'created',
        //   sortable: false,
        //   width:'80px',
        // },
        {
          label: 'Phone',
          field: 'phone',
          sortable: false,
          width:'120px',
        },
        // {
        //   label: 'Delete',
        //   field: 'delete',
        //   sortable: false,
        //   width:'80px',
        // },
        // {
        //   label: 'Drug',
        //   field: 'drug',
        //   sortable: false,
        //   width:'140px',
        // },
        // {
        //   label: 'Accurate',
        //   field: 'accurate',
        //   sortable: false,
        //   width:'140px',
        // },
        // {
        //   label: 'Amazon',
        //   field: 'amazon',
        //   sortable: false,
        //   width:'140px',
        // },
        // {
        //   label: 'Skills',
        //   field: 'skills',
        //   sortable: false,
        //   width:'40px',
        // },
        // {
        //   label: 'DNR',
        //   field: 'blacklist',
        //   width: '40px',
        //   sortable: false,
        // },
        // {
        //   label: 'Action',
        //   field: 'action',
        //   sortable: false,
        //   width:'84px',
        // }
      ]
    }
  },
  components: {
    Loader,
    EmployeeStatus,
    StarRating,
    ContractorStatus,
    InterviewStatus
  },
  methods: {
    activate() {
      this.isActive = true
      this.isTerminated = false
      this.isOther = false
    },
    terminate() {
      this.isActive = false
      this.isTerminated = true
      this.isOther = false
    },
    otherate() {
      this.isActive = false
      this.isTerminated = false
      this.isOther = true
    },
    async drop(row) {
      await fb.userDaysCollection.doc(row.id).delete()
    },
    lastLoginTime(row) {
      if (row && row.logins && row.logins.length > 0) {
        // Assuming logins is an array of timestamps
        const lastTimestamp = row.logins[row.logins.length - 1];
        return moment.unix(lastTimestamp).format("MM/DD/YYYY hh:mm:ss a");
      } else {
      }
    },
    onTextDrug (row) {
      this.performingRequestDrug = true
      let newText = {
        name: row.firstName,
        phone: row.phone,
        userId: row.id,
        type: 'drugReminder'
      }
      this.$store.dispatch('updateDrugReminder', newText)
      setTimeout(() => {
          this.performingRequestDrug = false
      }, 1000)
    },
    onTextBack (row) {
      this.performingRequestBack = true
      let newText = {
        name: row.firstName,
        phone: row.phone,
        userId: row.id,
        type: 'backgroundReminder'
      }
      this.$store.dispatch('updateBackgroundReminder', newText)
      setTimeout(() => {
          this.performingRequestBack = false
      }, 1000)
    },
    onTextAccurate (row) {
      this.performingRequestAccurate = true
      let newText = {
        name: row.firstName,
        phone: row.phone,
        userId: row.id,
        type: 'accurateReminder'
      }
      this.$store.dispatch('updateAccurateReminder', newText)
      setTimeout(() => {
          this.performingRequestAccurate = false
      }, 1000)
    },
    addPipeline(row) {
      console.log(row)
      this.$store.dispatch("addToPipeline", {
        eventId: this.eventId,
        userId: row.id
      })
    },
    setSelected(value) {
      console.log(value)
    },
    async updateStatus(row) {
      await fb.userDaysCollection.doc(row.id).update({
        userDetails: row.userDetails
      })
      await fb.usersCollection.doc(row.userId).update({
        employmentStatus: row.userDetails.employmentStatus
      })
    },
    async updateField(row, fieldName, value) {
      const updateObj = {}
      updateObj[fieldName] = value
      await fb.usersCollection.doc(row.id).update(updateObj)
    },
    async onTextReminder(row, type) {
      this[`performingRequest${type}`] = true
      const newText = {
        name: row.firstName,
        phone: row.phone,
        userId: row.id,
        type: `${type}Reminder`
      }
      await this.$store.dispatch(`update${type}Reminder`, newText)
      setTimeout(() => {
        this[`performingRequest${type}`] = false
      }, 1000)
    },
    showNote(r) {
      this.activeItem = r
    },
    closeNote(r) {
      this.activeItem = null
    },
    showBox() {
      this.showTextBox = true
    },
    hideBox() {
      this.showTextBox = false
    },
    selectionChanged(params) {
      this.batch = params.selectedRows
    },
    async sendTextMessage() {
      this.performingRequest3 = true
      const message = this.textMessage
      const messages = this.batch
        .filter(user => user.phone)
        .map(user => ({
          userId: user.id,
          phone: user.phone,
          message: message,
        }))
      await Promise.all(messages.map(msg => this.$store.dispatch('sendUserMessage', msg)))
      this.performingRequest3 = false
      this.textMessage = ''
      this.showTextBox = false
    },
    addWatch(row) {
      this.$store.dispatch('watchUser', row.id)
    },
    removeWatch(row) {
      this.$store.dispatch('unWatchUser', row.id)
    },
    isWatchList(row) {
      return this.userProfile && this.userProfile.userWatchList && this.userProfile.userWatchList.includes(row.id)
    },
    // mySearchFn(row, col, cellValue, searchTerm) {
    //   if (searchTerm === "") return true;
    //   return row.firstName.toLowerCase().startsWith(searchTerm.toLowerCase()) || row.lastName.toLowerCase().startsWith(searchTerm.toLowerCase()) 
    //   || row.shiftJob.toLowerCase().startsWith(searchTerm.toLowerCase()) (
    //   || 
    //     if (row.userDetails && row.userDetails.employmentAssignment) {
    //       row.userDetails.employmentAssignment.eventName.toLowerCase().startsWith(searchTerm.toLowerCase()) 
    //     }
    //   )
    // },
    mySearchFn(row, col, cellValue, searchTerm) {
  if (searchTerm === "") return true;

  const term = searchTerm.toLowerCase();

  // Check if any of the conditions match
  return (
    (row.firstName && row.firstName.toLowerCase().startsWith(term)) ||
    (row.lastName && row.lastName.toLowerCase().startsWith(term)) ||
    (row.shiftJob && row.shiftJob.toLowerCase().startsWith(term)) ||
    (row.userDetails &&
      row.userDetails.employmentAssignment &&
      row.userDetails.employmentAssignment.eventName &&
      row.userDetails.employmentAssignment.eventName.toLowerCase().startsWith(term))
  );
},
    deleteUser(item, index) {
      this.$store.dispatch("removeUserFromGroup", {
        group: this.group,
        user: item
      })
    },
    updatePosition(row) {
      fb.usersCollection.doc(row.id).update({
        positionPreference: row.positionPreference
      })
    },
    updatePreference(row) {
      fb.usersCollection.doc(row.id).update({
        shiftPreference: row.shiftPreference
      })
    },
    addBack(item, index) {
      this.$store.dispatch("addBackToPipeline", {
        userId: item.id,
        eventId: this.group.id
      })
    },
    addHired(item, index) {
      this.$store.dispatch("hireFromPipeline", {
        userId: item.id,
        eventId: this.group.id
      })
    },
    deletePipe(item, index) {
      console.log(item)
      const order = item.pipeline[0]
      fb.usersCollection.doc(item.id).update({
        cancellationReason: item.cancellationReason
      })
      this.$store.dispatch("removeFromPipeline", {
        userId: item.id || null,
        eventId: order
      })
    },
    acceptApplication(item, index) {
      this.$store.dispatch("acceptUserToGroup", {
        user: item,
        group: this.group
      })
    },
    holdApplication(item, index) {
      this.$store.dispatch("waitlistUserToGroup", {
        user: item,
        group: this.group
      })
    },
    deleteApplication(item, index) {
      this.$store.dispatch("removeUserApplicationFromGroup", {
        group: this.group,
        user: item
      })
    },
    formatDate(q) {
      if (q) {
        const postedDate = new Date(q.seconds * 1000);
        return moment(postedDate).format('MM/DD/YYYY')
      } else {
        return null
      }
    },
    isMenuShown(params) {
      return groupName(params)
    },
    clearPopover() {
      this.activeTitles = []
    },
    async showPopover(params) {
      this.activeTitles = await Promise.all(params.map(async group => {
        const doc = await fb.groupsCollection.doc(group).get()
        return doc.data().title
      }))
    },
    findLast(touchLog) {
      return touchLog[touchLog.length - 1]
    },
    formatStamp(stamp) {
      if (stamp) {
        const postedDate = new Date(stamp.seconds * 1000);
        return moment(postedDate).format('MM/DD/YYYY')
      }
    },
    formatLast(touchLog) {
      const last = this.findLast(touchLog)
      if (last && last.touched) {
        const postedDate = new Date(last.touched.seconds * 1000);
        return moment(postedDate).format('MM/DD/YYYY')
      } else {
        return null
      }
    },
    updatePipe(row) {
      console.log(row)
      fb.usersCollection.doc(row.id).update({
        estimatedStartDate: row.estimatedStartDate
      })
    },
    exportSelected() {
      const exportHeaders = [
        "First Name",
        "Last Name",
        "Email",
        "Phone",
        "City",
        "State"
      ]
      const exportItems = this.batch.map(user => [
        user.firstName || null,
        user.lastName || null,
        user.email || null,
        user.phone || null,
        user.address.city,
        user.address.state,
      ])
      this.$gapi.getGapiClient().then(gapi => {
        const exportService = new ExportService(exportHeaders, exportItems, gapi);
        exportService.export();
      });
    },
  },
  // methods: {
  //   mySearchFn(row, col, cellValue, searchTerm) {
  //     if (searchTerm === "") return true;
  //     return row.firstName.toLowerCase().startsWith(searchTerm.toLowerCase()) || row.lastName.toLowerCase().startsWith(searchTerm.toLowerCase())
  //   },
  //   selectionChanged(params) {
  //     this.batch = params.selectedRows
  //   },
  //   async showPopover(params) {
  //     this.activeTitles = await Promise.all(params.map(async group => {
  //       const doc = await fb.groupsCollection.doc(group).get()
  //       return doc.data().title
  //     }))
  //   },
  // },
  created() {
    this.$store.dispatch("getActiveWorkers") 
  },
  computed: {
    ...mapState(['userProfile', 'activeWorkers']),
    liveWorkers() {
      return this.activeWorkers.filter(worker => {
        return (worker.userDetails && worker.userDetails.employmentStatus && (worker.userDetails.employmentStatus == 'Active' || worker.userDetails.employmentStatus == 'Onboarding'))
      })
    },
    deadWorkers() {
      return this.activeWorkers.filter(worker => {
        return (worker.userDetails && worker.userDetails.employmentStatus && (worker.userDetails.employmentStatus == 'Terminated' || worker.userDetails.employmentStatus == 'Resigned'))
      })
    },
    otherWorkers() {
      return this.activeWorkers.filter(worker => {
        return (worker.userDetails && worker.userDetails.employmentStatus && (worker.userDetails.employmentStatus == 'On-Hold' || worker.userDetails.employmentStatus == 'On-Leave'))
      })
    },
  },
  beforeDestroy() {
    this.$store.dispatch("clearActiveWorkers")
  }
}
	
</script>